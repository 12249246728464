<script setup>
import { computed } from 'vue';

defineOptions({
    inheritAttrs: false,
});

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: [Boolean, Array, Set],
        required: false,
        default: false,
    },
    align: {
        type: String,
        required: false,
        default: 'left',
        validator: (x) => ['left', 'right'].includes(x),
    },
});

const modelValueProxy = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emit('update:modelValue', val);
    }
});

</script>

<template>
    <div class="checkbox checkbox-switch" :class="[$attrs.class, { 'checkbox-switch--right': align === 'right' }]">
        <label class="switch">
            <input
                v-bind="{ ...$attrs, class: undefined }"
                type="checkbox"
                role="switch"
                v-model="modelValueProxy"
            >
            <div class="slider-controls">
                <span class="slider-switch" />
                <div class="slider-label">
                    <slot />
                </div>
            </div>
        </label>
    </div>
</template>
